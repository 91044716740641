/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

.app {
    @apply text-center max-w-5xl mx-auto;
}

.heading {
    @apply font-bold text-2xl pb-4 font-extrabold;
}

.progressBar {
    @apply bg-gray-100 rounded-lg border mx-8 my-1 p-1 relative;
}

.progress {
    @apply bg-blue-400 rounded-lg h-full top-0 left-0 absolute z-10;
    transition: width 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.progressLabel {
    @apply text-gray-800 font-extrabold relative z-20;
}

@tailwind utilities;
